<template>
  <div>
    <!-- faq search section -->
    <section id="faq-search-filter">
      <b-row align-h="end" class="mr-1 mb-1" v-if="$can('create', 'A.QA')">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" pill
          :to="{ name: 'diko-faq-edit', params: { id: userData._id } }"><feather-icon icon="EditIcon" class="mr-50" />
          {{ $t("Create New Input") }}</b-button>
      </b-row>
      <b-card no-body class="faq-search">
        <b-card-body class="text-center">
          <h2 class="text-primary">
            {{ $t("You Can Ask Your Questions Here") }}
          </h2>
          <b-card-text class="mb-2">
            {{ $t(
                  "or you can find answers to all your questions by browsing our categories."
                )
              }}
          </b-card-text>
          <!-- form -->
          <b-form class="faq-search-input">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input id="searchbar" v-model="faqSearchQuery" placeholder="S.S.S içinda Ara..." />
            </b-input-group>
          </b-form>
          <!-- form -->
        </b-card-body>
      </b-card>
    </section>
    <!--/ faq search section -->
    <!-- frequently asked questions tabs pills -->
    <section id="faq-tabs">
      <b-tabs vertical content-class="col-12 col-md-8 col-lg-9" pills
        nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12" nav-class="nav-left">
        <!-- payment tab -->
        <b-tab v-for="(categoryObj, categoryName, index) in faqData" :key="categoryName" :active="!index">
          <!-- title -->
          <template #title>
            <feather-icon :icon="categoryObj.icon" size="18" class="mr-1" />
            <span class="font-weight-bold">{{ categoryObj.title }}</span>
            <!-- <button v-if="$can('delete', 'A.QA') && categoryObj.qa.length == 0"></button> -->
          </template>
          <faq-question-answer :options="categoryObj" @faqDeleted="faqDeleted" />
        </b-tab>
        <!--/ payment tab -->
        <!-- sitting lady image -->
        <template #tabs-end />
        <!--/ sitting lady image -->
      </b-tabs>
    </section>
    <!--/ frequently asked questions tabs pills -->
    <!-- contact us -->
    <section class="faq-contact">
      <b-row class="mt-5 pt-75 text-center">
        <b-col cols="12">
          <h2>Hala cevaplanmayan sorularınız mı var?</h2>
          <b-card-text class="mb-3"> Eğer cevaplanmayan sorularınız veya farklı sorularınız var ise bizimle iletişime
            geçebilirsiniz. </b-card-text>
        </b-col>
        <b-col sm="6">
          <b-card class="shadow-none py-1 faq-contact-card">
            <b-avatar size="42" rounded variant="light-primary" class="mb-2">
              <feather-icon icon="PhoneCallIcon" size="18" />
            </b-avatar>
            <h4>+90 (542) 502 2070</h4>
            <span class="text-body">Size yardımcı olabilmek için buradayız Diko'yu arayın</span>
          </b-card>
        </b-col>
        <b-col sm="6">
          <b-card class="shadow-none py-1 faq-contact-card">
            <b-avatar size="42" rounded variant="light-primary" class="mb-2">
              <feather-icon icon="MailIcon" size="18" />
            </b-avatar>
            <h4>info@dikotr.com</h4>
            <span class="text-body">Size en hızlı şekilde dönüş yapacağımızdan emin olun!</span>
          </b-card>
        </b-col>
      </b-row>
    </section>
    <!--/ contact us -->
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BForm,
  BInputGroup,
  BFormInput,
  BCardText,
  BInputGroupPrepend,
  BTabs,
  BTab,
  BImg,
  BRow,
  BCol,
  BAvatar,
  BButton,
} from "bootstrap-vue";
import axios from "@axios";
import FaqQuestionAnswer from "./FaqQuestionAnswer.vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BForm,
    BCard,
    BRow,
    BCol,
    BAvatar,
    BCardBody,
    BInputGroup,
    BFormInput,
    BCardText,
    BInputGroupPrepend,
    BTabs,
    BTab,
    BImg,
    FaqQuestionAnswer,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      faqSearchQuery: "",
      fullPageData: {},
      faqData: {},
      userData: {
        _id: 1,
      },
    };
  },
  watch: {
    faqSearchQuery: {
      immediate: true,
      handler() {
        this.fetchData();
      },
    },
  },
  created() {
    this.getData();
  },
  methods: {
    fetchData() {
      const q = this.faqSearchQuery || "";
      let qRegex = "";
      const queryLowered = q.toLowerCase();
      if (queryLowered.includes(" ")) {
        qRegex = new RegExp(
          "(?=.*" + queryLowered.split(" ").join(")(?=.*") + ").*$",
          "gi"
        );
      }
      console.log(qRegex);
      console.log(typeof qRegex);
      const filteredData = {};
      Object.entries(this.fullPageData).forEach((entry) => {
        const [categoryName, categoryObj] = entry;
        // console.log(entry);

        // eslint-disable-next-line arrow-body-style
        // console.log(categoryObj);
        const filteredQAndAOfCategory = categoryObj.qa.filter((qAndAObj) => {
          // console.log(qAndAObj);
          // console.log(
          //   qAndAObj.ans.toLowerCase().includes(queryLowered) ||
          //     qAndAObj.question.toLowerCase().includes(queryLowered)
          // );
          return qRegex
            ? qAndAObj.ans.toLowerCase().match(qRegex) ||
                qAndAObj.question.toLowerCase().match(qRegex)
            : q
            ? qAndAObj.ans.toLowerCase().includes(queryLowered) ||
              qAndAObj.question.toLowerCase().includes(queryLowered)
            : true;
        });
        if (filteredQAndAOfCategory.length > 0) {
          filteredData[categoryName] = {
            ...categoryObj,
            qa: filteredQAndAOfCategory,
            /*  q
              ? filteredQAndAOfCategory.forEach((qaOBJ) => {
                  qaOBJ.question = qaOBJ.question.replace(new RegExp(q, "gi"), (match) =>
                    match.toUpperCase()
                  );
                  qaOBJ.ans = qaOBJ.ans.replace(new RegExp(q, "gi"), (match) =>
                    match.toUpperCase()
                  );
                })
              : filteredQAndAOfCategory, */
          };
        }
      });
      this.faqData = filteredData;
      // this.$http.get("/faq/data", { params: { q: this.faqSearchQuery } }).then((res) => {
      //   this.faqData = res.data;
      //   console.log(res.data);
      // });
    },
    getData() {
      axios.get("/qa").then((res) => {
        this.fullPageData = res.data.faqData;
        this.fetchData();
      });
    },
    faqDeleted() {
      this.getData();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-faq.scss";
</style>
